export default function()
{
    let backToTop = document.querySelector('.back-to-top');
    let body = document.body,
        html = document.documentElement;

    let height = Math.max( body.scrollHeight, body.offsetHeight,
                       html.clientHeight, html.scrollHeight, html.offsetHeight );

    if (height < 2000) {
        backToTop.style.display = 'none';
    }
}
